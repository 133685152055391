/*
| Developed by Dirupt
| Filename : EmailField.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react';
import { Field } from 'formik';
import useTranslation from 'next-translate/useTranslation';
import { TextField, TextFieldProps } from '@/components/formik';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface EmailFieldProps extends Omit<TextFieldProps, 'name' | 'type' | 'label' | 'component' | 'form' | 'meta' | 'field'> {
  name?: string;
  label?: string;
}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const EmailField: React.FC<EmailFieldProps> = ({
  name,
  label,
  ...props
}) => {
  const {
    t
  } = useTranslation();
  return <Field {...props} component={TextField} name={name ?? 'email'} type="email" label={label || t('common:form.email')} data-testid="email" data-sentry-element="Field" data-sentry-component="EmailField" data-sentry-source-file="EmailField.tsx" />;
};