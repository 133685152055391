/*
| Developed by Dirupt
| Filename : SwitchField.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react';
import { FormControl, FormControlLabelProps, FormHelperText, styled, Switch, SwitchProps } from '@mui/material';
import { useField } from 'formik';
import _ from 'lodash';
import { FormControlLabel } from '@/components/formik';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface SwitchFieldProps extends Omit<SwitchProps, 'form' | 'name' | 'value' | 'checked' | 'defaultChecked'> {
  name: string;
  label?: string;
  labelPlacement?: FormControlLabelProps['labelPlacement'];
  formControlLabelProps?: Omit<FormControlLabelProps, 'label' | 'labelPlacement'>;
  helperText?: string;
}

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
export const FormControlStyled = styled(FormControl)(({
  theme
}) => ({
  '& .MuiFormHelperText-root': {
    marginLeft: theme.spacing(6.375)
  }
}));

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const SwitchField: React.FC<SwitchFieldProps> = ({
  name,
  label,
  labelPlacement = 'end',
  formControlLabelProps,
  helperText,
  ...props
}) => {
  const [field, meta, helpers] = useField(name);
  const {
    error,
    showError
  } = React.useMemo(() => {
    const showError = meta.touched;
    const error = typeof meta.error === 'string' ? meta.error : _.get(meta.error, name);
    return {
      error,
      showError
    };
  }, [meta.error, meta.touched, name]);

  // Control
  // ----------------------------------------------------------------------------
  const switchControl = <Switch {...field} {...props} type={'checkbox'} checked={field.value} onChange={(event, value) => {
    void helpers.setValue(value);
    if (props.onChange) {
      props.onChange(event, value);
    }
  }} />;

  // Render
  // ----------------------------------------------------------------------------
  return <FormControlStyled error={showError ? Boolean(error) : undefined} data-sentry-element="FormControlStyled" data-sentry-component="SwitchField" data-sentry-source-file="SwitchField.tsx">
			{label ? <FormControlLabel control={switchControl} label={label} labelPlacement={labelPlacement} {...formControlLabelProps} /> : switchControl}
			{showError && error || helperText ? <FormHelperText>{error || helperText}</FormHelperText> : null}
		</FormControlStyled>;
};