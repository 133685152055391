/*
| Developed by Dirupt
| Filename : PasswordField.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react';
import { Field } from 'formik';
import useTranslation from 'next-translate/useTranslation';
import { IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import * as Yup from 'yup';
import { Translate } from 'next-translate';
import { TextField, TextFieldProps } from '@/components/formik';
import { userConfiguration } from '@/services/api/config/user';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface PasswordFieldProps extends Omit<TextFieldProps, 'name' | 'type' | 'label' | 'component' | 'form' | 'meta' | 'field'> {
  label?: string;
  name?: string;
  helperText?: string;
}

/*
|--------------------------------------------------------------------------
| Yup validator for password
|--------------------------------------------------------------------------
*/
export const YupPasswordValidation = (t: Translate) => Yup.string().min(userConfiguration.validations.password.minLength, t('common:yup.password.min')).matches(userConfiguration.validations.password.lowercase, t('common:yup.password.lowercase')).matches(userConfiguration.validations.password.uppercase, t('common:yup.password.uppercase')).matches(userConfiguration.validations.password.number, t('common:yup.password.number')).matches(userConfiguration.validations.password.specialChar, t('common:yup.password.special')).required();

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const PasswordField: React.FC<PasswordFieldProps> = ({
  name,
  label,
  helperText,
  ...props
}) => {
  const {
    t
  } = useTranslation();
  const [showPassword, setShowPassword] = React.useState(false);
  return <Field {...props} component={TextField} name={name ?? 'password'} type={showPassword ? 'text' : 'password'} label={label ?? t('common:form.password.label')} helperText={helperText} data-testid="password" InputProps={{
    endAdornment: <IconButton aria-label="toggle password visibility" onClick={() => setShowPassword(!showPassword)} edge="end">
						{showPassword ? <VisibilityOff /> : <Visibility />}
					</IconButton>
  }} data-sentry-element="Field" data-sentry-component="PasswordField" data-sentry-source-file="PasswordField.tsx" />;
};