/*
| Developed by Dirupt
| Filename : NumberField.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { Field } from 'formik';
import { TextField, TextFieldProps } from '@/components/formik';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface NumberFieldProps extends Omit<TextFieldProps, 'field' | 'meta' | 'form'> {
  name: string;
  numericFormatProps?: NumericFormatProps;
}

/*
|--------------------------------------------------------------------------
| Sub component
|--------------------------------------------------------------------------
*/
export const NumericSubComponent = React.forwardRef<NumericFormatProps, NumericFormatProps>(function NumericFormatCustom(props, ref) {
  const {
    onChange,
    ...other
  } = props;
  return <NumericFormat {...other} getInputRef={ref} onValueChange={values => {
    onChange?.({
      // @ts-ignore
      target: {
        name: props.name as string,
        value: values.value
      }
    });
  }} valueIsNumericString />;
});

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const NumberField: React.FC<NumberFieldProps> = ({
  name,
  numericFormatProps,
  ...props
}) => {
  return <Field name={name} fullWidth component={TextField} {...props} inputProps={{
    ...numericFormatProps,
    ...props.inputProps
  }} InputProps={{
    inputComponent: NumericSubComponent as any,
    ...props.InputProps
  }} data-sentry-element="Field" data-sentry-component="NumberField" data-sentry-source-file="NumberField.tsx" />;
};