/*
| Developed by Dirupt
| Filename : TextField.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react';
import MuiTextField, { TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField';
import { fieldToTextField, TextFieldProps as FormikTextFieldProps } from 'formik-mui';
import { useField } from 'formik';
import _ from 'lodash';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface TextFieldProps extends FormikTextFieldProps {}

/*
|--------------------------------------------------------------------------
| Component with React.forwardRef
|--------------------------------------------------------------------------
*/
export const TextField: React.FC<TextFieldProps> = React.forwardRef((props, ref) => {
  return <MuiTextField {...fieldToTextField(props)} fullWidth inputRef={ref} InputProps={{
    fullWidth: true,
    ...props.InputProps
  }} />;
});
TextField.displayName = 'FormikMaterialUITextField';

/*
|--------------------------------------------------------------------------
| Component with useField from Formik
|--------------------------------------------------------------------------
*/
export interface TextFieldInputProps extends Omit<MuiTextFieldProps, 'name' | 'value'> {
  name: string;
  disabledError?: boolean;
}
export const TextFieldInput: React.FC<TextFieldInputProps> = React.forwardRef(({
  name,
  disabledError,
  ...props
}, ref) => {
  const [field, meta] = useField(name);
  const {
    error,
    showError
  } = React.useMemo(() => {
    const showError = meta.touched;
    const error = typeof meta.error === 'string' ? meta.error : _.get(meta.error, name);
    return {
      error,
      showError
    };
  }, [meta.error, meta.touched, name]);

  // Render
  //--------------------------------------------------------------------------
  return <MuiTextField {...field} {...props} error={Boolean(showError && !disabledError)} helperText={showError ? error : props.helperText} inputRef={ref} InputProps={{
    fullWidth: true,
    ...props.InputProps
  }} />;
});
TextFieldInput.displayName = 'FormikMaterialUITextFieldInput';