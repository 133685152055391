/*
| Developed by Dirupt
| Filename : DropzoneField.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react';
import { Accept, DropzoneProps, useDropzone } from 'react-dropzone';
import { useField } from 'formik';
import { Box, BoxProps, styled } from '@mui/material';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface DropzoneFieldProps {
  children: React.ReactNode;
  name: string;
  accept?: Accept;
  maxFiles?: number;
  maxSize?: number;
  dropzoneProps?: Omit<DropzoneProps, 'accept' | 'maxFiles' | 'maxSize'>;
}

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const DropzoneContainer = styled(Box, {
  shouldForwardProp: prop => prop !== 'isDragActive'
})<BoxProps & {
  isDragActive?: boolean;
}>(({
  theme,
  isDragActive
}) => ({
  ...(isDragActive ? {
    border: `1px dashed ${theme.palette.primary.main}`
  } : {})
}));

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const DropzoneField: React.FC<DropzoneFieldProps> = ({
  children,
  name,
  accept = {
    'image/png': ['.png'],
    'image/jpeg': ['.jpg', '.jpeg']
  },
  maxFiles = 1,
  maxSize = 10 * 1024 * 1024,
  // 10MB
  dropzoneProps
}) => {
  const [,, helpers] = useField(name);

  // Methods
  // ----------------------------------------------------------------------------
  const onDrop = React.useCallback((acceptedFiles: Array<File>) => {
    // If multiple files are allowed, we set all of them, otherwise just the first one
    const filesToSet = maxFiles > 1 ? acceptedFiles : [acceptedFiles[0]];
    void helpers.setValue(filesToSet);
  }, [helpers, maxFiles]);

  // Dropzone configuration
  // ----------------------------------------------------------------------------
  const {
    getRootProps,
    getInputProps,
    isDragActive
  } = useDropzone({
    onDrop,
    accept,
    maxFiles,
    maxSize,
    ...dropzoneProps
  });

  // Render
  // ----------------------------------------------------------------------------
  return <DropzoneContainer {...getRootProps()} isDragActive={isDragActive} data-sentry-element="DropzoneContainer" data-sentry-component="DropzoneField" data-sentry-source-file="DropzoneField.tsx">
			<input {...getInputProps()} name={name} />
			{children}
		</DropzoneContainer>;
};