/*
| Developed by Dirupt
| Filename : DateInput.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react';
import { useFormikContext } from 'formik';
import { DatePicker } from '@mui/x-date-pickers';
import _ from 'lodash';
import { TextFieldRaw } from '@/components/formik';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface DateInputProps {
  name: string;
  label: string;
}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const DateInput: React.FC<DateInputProps> = ({
  name,
  label
}) => {
  const {
    setFieldValue,
    setFieldTouched,
    isSubmitting,
    values,
    errors,
    touched
  } = useFormikContext<{
    [name: string]: string;
  }>();
  const {
    error,
    showError
  } = React.useMemo(() => {
    const showError = _.get(touched, name);
    const error = _.get(errors, name);
    return {
      error,
      showError
    };
  }, [errors, name, touched]);

  // Render
  //--------------------------------------------------------------------------
  return <DatePicker sx={{
    width: '100%'
  }} value={_.get(values, name)} onChange={async value => {
    await setFieldTouched(name, true);
    await setFieldValue(name, value);
  }} formatDensity="spacious" disabled={isSubmitting} label={label} slots={{
    // @ts-ignore
    textField: TextFieldRaw
  }} slotProps={{
    textField: {
      inputProps: {
        'data-testid': 'input-date'
      },
      helperText: showError ? error : '',
      error: showError ? Boolean(error) : false
    }
  }} data-sentry-element="DatePicker" data-sentry-component="DateInput" data-sentry-source-file="DateInput.tsx" />;
};