/*
| Developed by Dirupt
| Filename : user.ts
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export type UserConfiguration = {
	validations: {
		password: {
			minLength: number
			lowercase: RegExp
			uppercase: RegExp
			number: RegExp
			specialChar: RegExp
		}
	}
}

/*
|--------------------------------------------------------------------------
| User configuration
|--------------------------------------------------------------------------
*/
export const userConfiguration: UserConfiguration = {
	validations: {
		password: {
			minLength: 8,
			lowercase: /[a-z]/,
			uppercase: /[A-Z]/,
			number: /[0-9]/,
			specialChar: /[^a-zA-Z0-9]/,
		},
	},
}
