/*
| Developed by Dirupt
| Filename : FormControlLabel.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import { FormControlLabel as MuiFormControlLabel, styled } from '@mui/material';
export const FormControlLabel = styled(MuiFormControlLabel)(({
  theme
}) => ({
  marginLeft: 0,
  marginRight: 0,
  '& .MuiFormControlLabel-label': {
    marginLeft: theme.spacing(1)
  }
}));