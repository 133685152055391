/*
| Developed by Dirupt
| Filename : MTypographyUnderline.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react';
import { Box, BoxProps, styled, Typography, TypographyProps } from '@mui/material';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface MTypographyUnderlineProps extends TypographyProps {
  boxProps?: BoxProps;
  noBottomMargin?: boolean;
  align?: 'left' | 'center' | 'right';
}

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const TypographyWithUnderline = styled(Box, {
  shouldForwardProp: prop => prop !== 'noBottomMargin' && prop !== 'align'
})<BoxProps & Pick<MTypographyUnderlineProps, 'noBottomMargin' | 'align'>>(({
  theme,
  noBottomMargin,
  align
}) => ({
  position: 'relative',
  ...(!noBottomMargin && {
    marginBottom: theme.spacing(2)
  }),
  '&::before': {
    content: '""',
    position: 'absolute',
    ...(align === 'left' && {
      bottom: -5,
      left: 0
    }),
    ...(align === 'center' && {
      bottom: -5,
      left: '50%',
      transform: 'translateX(-50%)'
    }),
    ...(align === 'right' && {
      bottom: -5,
      right: 0
    }),
    width: theme.spacing(6),
    height: 3,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 99
  }
}));

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const MTypographyUnderline: React.FC<MTypographyUnderlineProps> = ({
  children,
  boxProps,
  noBottomMargin,
  align = 'left',
  ...props
}) => {
  return <TypographyWithUnderline noBottomMargin={noBottomMargin} align={align} {...boxProps} data-sentry-element="TypographyWithUnderline" data-sentry-component="MTypographyUnderline" data-sentry-source-file="MTypographyUnderline.tsx">
			<Typography fontWeight={500} textAlign={align} {...props} data-sentry-element="Typography" data-sentry-source-file="MTypographyUnderline.tsx">
				{children}
			</Typography>
		</TypographyWithUnderline>;
};