/*
| Developed by Dirupt
| Filename : DateTimeInput.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react';
import { DateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import { FormControl, FormHelperText } from '@mui/material';
import { TextFieldRaw } from '@/components/formik';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface DateTimeInputProps extends DateTimePickerProps<any> {
  name: string;
  label: string;
  helperText?: string;
}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const DateTimeInput: React.FC<DateTimeInputProps> = ({
  name,
  label,
  helperText,
  ...props
}) => {
  const {
    setFieldValue,
    setFieldTouched,
    isSubmitting,
    values,
    errors,
    touched
  } = useFormikContext<{
    [name: string]: string;
  }>();
  const {
    error,
    showError
  } = React.useMemo(() => {
    const showError = _.get(touched, name);
    const error = _.get(errors, name);
    return {
      error,
      showError
    };
  }, [errors, name, touched]);

  // Render
  //--------------------------------------------------------------------------
  return <FormControl error={showError ? Boolean(error) : undefined} data-sentry-element="FormControl" data-sentry-component="DateTimeInput" data-sentry-source-file="DateTimeInput.tsx">
			<DateTimePicker sx={{
      width: '100%'
    }} slots={{
      // @ts-ignore
      textField: TextFieldRaw,
      ...props.slots
    }} slotProps={{
      textField: {
        inputProps: {
          'data-testid': 'input-date-time'
        },
        helperText: showError ? error : '',
        error: showError ? Boolean(error) : false
      },
      ...props.slotProps
    }} {...props} value={_.get(values, name)} onChange={async value => {
      await setFieldTouched(name, true);
      await setFieldValue(name, value);
    }} formatDensity="spacious" disabled={isSubmitting} label={label} data-sentry-element="DateTimePicker" data-sentry-source-file="DateTimeInput.tsx" />
			{showError && error || helperText ? <FormHelperText>{error || helperText}</FormHelperText> : null}
		</FormControl>;
};