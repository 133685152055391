/*
| Developed by Dirupt
| Filename : TextFieldRaw.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface TextFieldRawProps extends Omit<TextFieldProps<'standard'>, 'variant'> {}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const TextFieldRaw: React.FC<TextFieldRawProps> = ({
  InputProps,
  ...props
}) => {
  return <TextField fullWidth variant={'outlined'} {...props} InputProps={{
    fullWidth: true,
    ...InputProps
  }} data-sentry-element="TextField" data-sentry-component="TextFieldRaw" data-sentry-source-file="TextFieldRaw.tsx" />;
};